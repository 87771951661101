/* Custom heading styles */
.markdown-h1 {
  font-size: 2em;
  font-weight: 700;
  margin-bottom: 1rem;
}

.markdown-h2 {
  font-size: 1.75em;
  font-weight: 700;
  margin-bottom: 0.75rem;
}

.markdown-h3 {
  font-size: 1.5em;
  font-weight: 600;
  margin-bottom: 0.75rem;
}

.markdown-h4 {
  font-size: 1.25em;
  font-weight: 600;
  margin-bottom: 0.75rem;
}

.markdown-h5 {
  font-size: 1em;
  font-weight: 600;
  margin-bottom: 0.75rem;
}

.markdown-h6 {
  font-size: 0.875em;
  font-weight: 600;
  margin-bottom: 0.75rem;
}

/* List styles */
.markdown-ul {
  padding-left: 1.5rem;
  list-style-type: disc;
}

.markdown-ol {
  padding-left: 1.5rem;
  list-style-type: decimal;
}

.markdown-li {
  line-height: 1.6;
  margin-bottom: 0.5rem;
}
