@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;1,200&family=Roboto:wght@300&display=swap');
body {
  font-family: 'Poppins', sans-serif !important;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #418db8;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #196591;
}

#chat-container::-webkit-scrollbar {
  display: none !important;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltip-text {
  visibility: hidden;
  width: 400px;
  background-color: #282424;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: 130%; /* Position the tooltip above the icon */
  left: 20%;
  margin-left: -150px;
  opacity: 0;
  transition: opacity 0.5s;
  font-size: 12px;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
